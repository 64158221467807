// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GrammarSection.css */

.grammar-section {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 5px;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .grammar-lesson {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 20px;
    margin-top: 0;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .example {
    font-weight: bold;
    margin-top: 10px;
  }
  /* styles.css */
.lesson-img {
  width: 100px; /* Desired width */
  height: 100px; /* Desired height */
}
`, "",{"version":3,"sources":["webpack://./src/GrammarSection.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;EACA,eAAe;AACjB;EACE,YAAY,EAAE,kBAAkB;EAChC,aAAa,EAAE,mBAAmB;AACpC","sourcesContent":["/* GrammarSection.css */\n\n.grammar-section {\n    background-color: #f4f4f4;\n    padding: 20px;\n    border-radius: 5px;\n    margin: 20px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .grammar-lesson {\n    margin-bottom: 20px;\n    border: 1px solid #ddd;\n    padding: 10px;\n    border-radius: 5px;\n  }\n  \n  h2 {\n    font-size: 24px;\n    margin-bottom: 10px;\n  }\n  \n  h3 {\n    font-size: 20px;\n    margin-top: 0;\n  }\n  \n  p {\n    font-size: 16px;\n    margin-bottom: 10px;\n  }\n  \n  .example {\n    font-weight: bold;\n    margin-top: 10px;\n  }\n  /* styles.css */\n.lesson-img {\n  width: 100px; /* Desired width */\n  height: 100px; /* Desired height */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
